import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"

import { Hero, Nav, About, Location, Gallery } from "page_components/portfolio"

const Portfolio = ({ pageContext, location }) => {
  return (
    <Layout
      location={location}
      seo={{ title: pageContext?.data?.title }}
      rmLangSwitcher
    >
      <Breadcrumbs
        title={pageContext?.data?.title}
        parent={{ label: "Portfolio", url: "/portfolio" }}
      />
      <Hero data={pageContext.data} />
      <Nav data={pageContext.data} />
      <About data={pageContext.data} />
      <Location data={pageContext.data} />
      <Gallery data={pageContext.data} />
    </Layout>
  )
}

export default Portfolio
